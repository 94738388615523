<template>

  <v-dialog
    width="900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Auto-Notification of <span class="font-weight-black">{{ name }}</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-skeleton-loader
        v-if="loading"
        class="mx-auto"
        max-width="100%"
        type="image, actions"
      ></v-skeleton-loader>

      <v-card-text v-if="!loading">

        <template>
          <v-row>
            <v-col>
              <v-row>
                <v-col class="pb-0 secondary--text text-uppercase font-weight-medium">
                  Board Members
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex align-center" cols="7">
                  <span class="d-flex flex-row align-center">
                    <p class="ma-0 mr-6">Include links to</p>
                    <v-switch
                      inset
                      label="Agenda"
                      color="secondary"
                      class="mt-0 pa-0 mr-4"
                      v-model="notificationData.board_agenda"
                      hide-details
                    ></v-switch>
                    <v-switch
                      inset
                      label="Packet"
                      color="secondary"
                      class="mt-0 pa-0 mr-4"
                      v-model="notificationData.board_packet"
                      hide-details
                    ></v-switch>
                  </span>
                </v-col>
                <v-col class="d-flex align-center" cols="4">
                  <span class="d-flex flex-row align-center">
                    <v-select
                      :items="timeIntervals"
                      item-text="text"
                      item-value="value"
                      label="Interval"
                      v-model="boardInterval"
                      outlined
                      dense
                      hide-details
                    ></v-select>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="regularGrey"
                          size="18"
                          class="ml-3"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <span>
                        Explanation
                      </span>
                    </v-tooltip>
                  </span>
                </v-col>
                <v-col class="d-flex align-center justify-end" cols="1">
                  <span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="expand1 = !expand1"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon size="20">mdi-email-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        See users
                      </span>
                    </v-tooltip>
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-expand-transition>
            <v-row v-show="expand1">
              <v-col class="veryLightGrey mb-6 custom-view-list">
                  <span class="custom-view-title darkGrey--text">
                    {{ this.emails.length }} EMAILS
                  </span>
                  <ul class="custom-list-huge">
                    <li class="font-weight-light" v-for="item in emails" :key="item">
                      {{ item }}
                    </li>
                  </ul>
              </v-col>
            </v-row>
          </v-expand-transition>

          <v-row>
            <v-divider class="custom-divider"></v-divider>
          </v-row>

          <v-row>
            <v-col>
              <v-row>
                <v-col class="pb-0 secondary--text text-uppercase font-weight-medium">
                  Staff
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex align-center" cols="7">
                  <span class="d-flex flex-row align-center">
                    <p class="ma-0 mr-6">Include links to</p>
                    <v-switch
                      inset
                      label="Agenda"
                      color="secondary"
                      class="mt-0 pa-0 mr-4"
                      v-model="notificationData.staff_agenda"
                      hide-details
                    ></v-switch>
                    <v-switch
                      inset
                      label="Packet"
                      color="secondary"
                      class="mt-0 pa-0 mr-4"
                      v-model="notificationData.staff_packet"
                      hide-details
                    ></v-switch>
                  </span>
                </v-col>
                <v-col class="d-flex align-center" cols="4">
                  <span class="d-flex flex-row align-center">
                    <v-select
                      :items="timeIntervals"
                      item-text="text"
                      item-value="value"
                      label="Interval"
                      v-model="staffInterval"
                      outlined
                      dense
                      hide-details
                    ></v-select>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="regularGrey"
                          size="18"
                          class="ml-3"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <span>
                        Explanation
                      </span>
                    </v-tooltip>
                  </span>
                </v-col>
                <v-col class="d-flex align-center justify-end" cols="1">
                  <span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="expand2 = !expand2"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon size="20">mdi-email-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        Manage users
                      </span>
                    </v-tooltip>
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-expand-transition>
            <v-row v-show="expand2">
              <v-col class="mb-6 custom-view-list">
                  <span class="darkGrey--text">
                    Manage Emails
                  </span>
                  <v-select
                    v-model="emailsSelected"
                    :items="emails"
                    label="Select Emails"
                    multiple
                    outlined
                    class="mt-4"
                    hide-details
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index < 4">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span
                        v-if="index == 4"
                        class="grey--text caption"
                      >
                      +{{ emailsSelected.length - 4 }} others
                    </span>
                    </template>
                  </v-select>
              </v-col>
            </v-row>
          </v-expand-transition>

          <v-row>
            <v-divider class="custom-divider"></v-divider>
          </v-row>

          <v-row>
            <v-col>
              <v-row>
                <v-col class="pb-0 secondary--text text-uppercase font-weight-medium">
                  Public
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex align-center" cols="7">
                  <span class="d-flex flex-row align-center">
                    <p class="ma-0 mr-6">Include links to</p>
                    <v-switch
                      inset
                      label="Agenda"
                      color="secondary"
                      class="mt-0 pa-0 mr-4"
                      v-model="notificationData.public_agenda"
                      hide-details
                    ></v-switch>
                    <v-switch
                      inset
                      label="Packet"
                      color="secondary"
                      class="mt-0 pa-0 mr-4"
                      v-model="notificationData.public_packet"
                      hide-details
                    ></v-switch>
                  </span>
                </v-col>
                <v-col class="d-flex align-center" cols="4">
                  <span class="d-flex flex-row align-center">
                    <v-select
                      :items="timeIntervals"
                      item-text="text"
                      item-value="value"
                      label="Interval"
                      v-model="publicInterval"
                      outlined
                      dense
                      hide-details
                    ></v-select>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="regularGrey"
                          size="18"
                          class="ml-3"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <span>
                        Explanation
                      </span>
                    </v-tooltip>
                  </span>
                </v-col>
                <v-col class="d-flex align-center justify-end" cols="1">
                  <span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="expand3 = !expand3"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon size="20">mdi-email-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        See users
                      </span>
                    </v-tooltip>
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-expand-transition>
            <v-row v-show="expand3">
              <v-col class="veryLightGrey mb-6 custom-view-list">
                  <span class="custom-view-title darkGrey--text">
                    {{ this.emails.length }} EMAILS
                  </span>
                <ul class="custom-list-huge">
                  <li class="font-weight-light" v-for="item in emails" :key="item">
                    {{ item }}
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-expand-transition>

          <v-row>
            <v-divider class="custom-divider"></v-divider>
          </v-row>

          <v-expand-transition>
            <v-row v-show="expand4">
              <v-col class="veryLightGrey mb-6 custom-view-list">
                  <span class="custom-view-title darkGrey--text">
                    {{ this.emails.length }} EMAILS
                  </span>
                <ul class="custom-list-huge">
                  <li class="font-weight-light" v-for="item in emails" :key="item">
                    {{ item }}
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-expand-transition>

          <v-row v-for="(notification, index) in groupNotifications" v-bind:key="notification.group_name" v-bind:id="notification.id" v-bind:boardId="notification.board_id" v-bind:noticeInterval="notification.notice_interval" v-bind:agenda="notification.agenda" v-bind:packet="notification.packet">
            <v-col>
              <v-row>
                <v-col class="pb-0 secondary--text text-uppercase font-weight-medium">
                  {{ notification.group_name }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex align-center" cols="7">
                  <span class="d-flex flex-row align-center">
                    <p class="ma-0 mr-6">Include links to</p>
                    <v-switch
                      inset
                      label="Agenda"
                      color="secondary"
                      class="mt-0 pa-0 mr-4"
                      v-model="notification.agenda"
                      @click="setTempVal(index)"
                      hide-details
                    ></v-switch>
                    <v-switch
                      inset
                      label="Packet"
                      color="secondary"
                      class="mt-0 pa-0 mr-4"
                      v-model="notification.packet"
                      @click="setTempVal(index)"
                      hide-details
                    ></v-switch>
                  </span>
                </v-col>
                <v-col class="d-flex align-center" cols="4">
                  <span class="d-flex flex-row align-center">
                    <v-select
                      :items="timeIntervals"
                      item-text="text"
                      :item-value="notification.notice_interval"
                      label="Interval"
                      v-model="notification.notice_interval"
                      @click="setTempVal(index)"
                      outlined
                      dense
                      hide-details
                    ></v-select>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="regularGrey"
                          size="18"
                          class="ml-3"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <span>
                        Explanation
                      </span>
                    </v-tooltip>
                  </span>
                </v-col>
                <v-col class="d-flex align-center justify-end" cols="1">
                  <span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="expand1 = !expand1"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon size="20">mdi-email-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        See users
                      </span>
                    </v-tooltip>
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </template>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
/* eslint-disable camelcase */
import Vue from 'vue'
import axios from 'axios'

// Initial state
function initialState () {
  return {
    timeIntervals: [
      { text: 'No Notification', value: -1 },
      { text: 'Immediately', value: 0 },
      { text: '30 min', value: 30 },
      { text: '1 hour', value: 60 },
      { text: '3 hours', value: 180 }
    ],
    itemsMenu: [
      { icon: 'mdi-email', text: 'View email addresses' },
      { icon: 'mdi-email-edit', text: 'Manage email addresses' }
    ],
    expand1: false,
    expand2: false,
    expand3: false,
    expand4: false,
    emails: ['Charles Simon', 'Vee Caron', 'Rico Reis', 'Raph Leroux', 'Tony Cappozi', 'Kyler Miles', 'Sean Canlas', 'Stephen Cawley', 'Arthur da Silva', 'Miguel Reis', 'Alline Pereira'],
    emailsSelected: ['Charles Simon', 'Vee Caron', 'Rico Reis', 'Raph Leroux', 'Tony Cappozi', 'Kyler Miles'],
    notificationData: '',
    tempIndex: null,
    boardInterval: { value: '0' },
    staffInterval: { value: '0' },
    publicInterval: { value: '0' },
    loading: true,
    readyForAPI: false,
    groupNotifications: '',
    transmitData: ''
  }
}

export default Vue.extend({
  name: 'BoardAutoNotification',
  props: {
    value: {
      required: true,
      type: Boolean
    },
    boardId: {
      required: false,
      type: Number
    },
    name: {
      required: true,
      type: String
    }
  },
  data: function () {
    return initialState()
  },
  methods: {
    setTempVal (value) {
      this.tempIndex = value
    },
    getDataFromAPI () {
      this.loading = true
      axios.post(process.env.VUE_APP_BACKEND_URL + '/api/admin/board-notifications', { boardId: this.boardId, clientId: this.$store.state.client.activeClient.clientID })
        .then((response) => {
          this.notificationData = response.data.board
          this.boardInterval.value = this.notificationData.board_interval
          this.staffInterval.value = this.notificationData.staff_interval
          this.publicInterval.value = this.notificationData.public_interval
          this.groupNotifications = response.data.group_list
          this.loading = false
        }).catch(function (error) {
          console.log(error)
        }).then(() => {
          this.readyForAPI = true
        })
    },
    updateDataAtAPI (objectType, data = null) {
      if (objectType === 1) {
        this.transmitData = this.notificationData
      } else {
        this.transmitData = data
      }
      axios.put(process.env.VUE_APP_BACKEND_URL + '/api/admin/board-notifications/' + this.notificationData.id, { data: this.transmitData, mode: objectType })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  watch: {
    show: function (stateChange) {
      if (stateChange === true) {
        this.getDataFromAPI()
      } else {
        Object.assign(this.$data, initialState())
      }
    },
    groupNotifications: {
      deep: true,
      handler (newValue) {
        if (this.readyForAPI === true) {
          this.updateDataAtAPI(2, newValue[this.tempIndex])
        }
      }
    },
    notificationData: {
      deep: true,
      handler () {
        if (this.readyForAPI === true) {
          this.updateDataAtAPI(1)
        }
      }
    },
    boardInterval: {
      deep: true,
      handler (newValue) {
        if (typeof newValue !== 'object' && this.notificationData.board_interval !== newValue) {
          this.notificationData.board_interval = newValue
        }
      }
    },
    staffInterval: {
      deep: true,
      handler (newValue) {
        if (typeof newValue !== 'object' && this.notificationData.staff_interval !== newValue) {
          this.notificationData.staff_interval = newValue
        }
      }
    },
    publicInterval: {
      deep: true,
      handler (newValue) {
        if (typeof newValue !== 'object' && this.notificationData.public_interval !== newValue) {
          this.notificationData.public_interval = newValue
        }
      }
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
