<template>

  <v-dialog
    width="600"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Social Media of <span class="font-weight-black">{{ name }}</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row class="py-2">
          <v-col>
            <v-switch
              inset
              label="Facebook"
              color="secondary"
              hide-details
              class="mt-0 pa-0"
              v-model="switch1"
            ></v-switch>
          </v-col>
        </v-row>

        <v-row>
          <v-divider class="custom-divider"></v-divider>
        </v-row>

        <v-row class="py-2">
          <v-col>
            <v-switch
              inset
              label="Twitter"
              color="secondary"
              hide-details
              class="mt-0 pa-0"
              v-model="switch2"
            ></v-switch>
          </v-col>
        </v-row>

        <v-row>
          <v-divider class="custom-divider"></v-divider>
        </v-row>

        <v-row class="py-2">
          <v-col>
            <v-switch
              inset
              label="Linkedin"
              color="secondary"
              hide-details
              class="mt-0 pa-0"
              v-model="switch3"
            ></v-switch>
          </v-col>
        </v-row>

        <v-row>
          <v-divider class="custom-divider"></v-divider>
        </v-row>

        <v-row class="py-2">
          <v-col>
            <v-switch
              inset
              label="Instagram"
              color="secondary"
              hide-details
              disabled
              class="mt-0 pa-0"
              v-model="switch4"
            ></v-switch>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'BoardSocialMedia',
  props: {
    value: {
      required: true,
      type: Boolean
    },
    boardId: {
      required: false,
      type: Number
    },
    name: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      switch1: true,
      switch2: false,
      switch3: false,
      switch4: false
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
