<template>
<v-dialog width="900" v-model="show" :persistent="this.$store.state.modalNotCloseOutside" :no-click-animation="this.$store.state.modalWithoutAnimation">
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>
          <span v-if="modeType=='create'">New Board</span>
          <span v-if="modeType=='edit'">Edit Board <span class="font-weight-black">{{ name }}</span></span>
        </span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn icon color="grey" @click="show=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col>
            <v-text-field
                outlined
                label="Name"
                v-model="bName"
                name="boardName"
                required
                :rules="nameRules"
                :counter="50"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-menu ref="menu" v-model="menu2" :close-on-content-click="false" :nudge-right="40" :return-value.sync="time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                label="Usual Meeting Time"
                v-model="bMeetingTime"
                name="boardMeetingTime"
                append-icon="mdi-clock-time-four-outline"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                :rules="[v => !!v || 'Meeting Time is required']"
                ></v-text-field>
              </template>
              <v-time-picker
              v-if="menu2"
              v-model="bMeetingTime"
              format="ampm"
              full-width
              @click:minute="$refs.menu.save(meetingTime)"
              required
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-textarea
            outlined
            v-model="bDescription"
            name="boardDescription"
            label="Description"
            :counter="512"
            required
            :rules="descRules"
            ></v-textarea>
          </v-col>
          <v-col>
            <v-textarea
            outlined
            v-model="bMeetingLoc"
            name="boardMeetingLoc"
            label="Usual Meeting Location"
            required
            :rules="[v => !!v || 'Meeting Location is required']"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show = false">Cancel</v-btn>
        <v-btn depressed width="130" color="secondary" @click="saveBoard">
          <v-progress-circular v-if="saving===true" indeterminate size="16"></v-progress-circular>
          <span v-if="modeType=='create'">Add</span>
          <span v-if="modeType=='edit'">Update</span>
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-form>
</v-dialog>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  name: 'BoardCreateUpdate',
  props: {
    value: {
      required: true,
      type: Boolean
    },
    modeType: {
      required: true,
      type: String
    },
    boardId: {
      required: false,
      type: Number
    },
    name: {
      required: true,
      type: String
    },
    meetingTime: {
      required: true,
      type: String
    },
    description: {
      required: true,
      type: String
    },
    meetingLoc: {
      required: true,
      type: String
    }
  },
  data: () => ({
    valid: true,
    date: new Date().toISOString().substr(0, 10),
    form: {
      name: '',
      description: '',
      meeting_time: '',
      meeting_location: '',
      client_id: ''
    },
    time: null,
    menu2: false,
    modal2: false,
    saving: false,
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 50) || 'Name must be less than 50 characters'
    ],
    descRules: [
      v => !!v || 'Description is required',
      v => (v && v.length <= 512) || 'Description must be less than 512 characters'
    ]
  }),
  methods: {
    saveBoard () {
      this.saving = true
      this.form.client_id = this.$store.state.client.activeClient.clientID
      const axiosAction = (this.modeType === 'create') ? 'post' : 'put'
      const axiosUrl = (this.modeType === 'edit') ? '/api/admin/boards/' + this.boardId : '/api/admin/boards'
      axios({
        method: axiosAction,
        url: process.env.VUE_APP_BACKEND_URL + axiosUrl,
        data: this.form
      }).then((response) => {
        this.closeModal()
        this.$emit('reload', true)
      })
    },
    closeModal () {
      this.$refs.form.reset()
      this.show = false
      this.saving = false
    }
  },
  watch: {
    show: function (stateChange) {
      this.form.client_id = this.$store.state.client.activeClient.clientID
      if (this.modeType === 'create') {
        this.$refs.form.reset()
        this.bName = ''
        this.bDescription = ''
        this.bMeetingTime = ''
        this.bMeetingLoc = ''
        // To prevent it from getting "cranky" on the second modal load
        this.meetingTime = ''
      } else {
        this.bName = this.name
        this.bDescription = this.description
        this.bMeetingTime = this.meetingTime
        this.bMeetingLoc = this.meetingLoc
      }
    }
  },
  computed: {
    bName: {
      get () {
        return this.name
      },
      set (val) {
        this.form.name = val
      }
    },
    bDescription: {
      get () {
        return this.description
      },
      set (val) {
        this.form.description = val
      }
    },
    bMeetingTime: {
      get () {
        return this.meetingTime
      },
      set (val) {
        // Temporary until model is fixed. TODO: Fix this
        this.meetingTime = val
        // END
        this.form.meeting_time = val
      }
    },
    bMeetingLoc: {
      get () {
        return this.meetingLoc
      },
      set (val) {
        this.form.meeting_location = val
      }
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
