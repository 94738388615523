<template>

  <v-dialog
    width="900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Approval Chain Linkages of <span class="font-weight-black">{{ name }}</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-skeleton-loader
        v-if="loading"
        class="mx-auto"
        max-width="100%"
        type="image, actions"
      ></v-skeleton-loader>

      <v-card-text v-if="!loading">

        <v-row>
          <v-col cols="6" class="mb-6">
            <v-text-field
              label="Search"
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
        </v-row>

        <template>

          <v-row class="py-2" v-for="(chain, index) in approvalChains" v-bind:key="chain.id" v-bind:chain="chain">
            <v-col>
              <v-switch
                inset
                :label="chain.name"
                color="secondary"
                class="mt-0 pa-0"
                v-model="chain.is_visible"
                @click="setTempVal(index)"
                hide-details
              ></v-switch>
              <p v-if="chain.status === 'inactive'" class="important--text font-italic pl-14 mb-0">Deactivated</p>
            </v-col>
            <v-col class="py-0 d-flex align-center text-caption">
              {{ chain.description }}
            </v-col>
          </v-row>

        </template>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

// Initial state
function initialState () {
  return {
    approvalChains: '',
    tempIndex: null,
    loading: true,
    readyForAPI: false
  }
}

export default Vue.extend({
  name: 'BoardApprovalChain',
  props: {
    value: {
      required: true,
      type: Boolean
    },
    boardId: {
      required: false,
      type: Number
    },
    name: {
      required: true,
      type: String
    }
  },
  data: function () {
    return initialState()
  },
  watch: {
    show (value) {
      if (value === true) {
        this.getDataFromApi()
      }
    },
    approvalChains: {
      deep: true,
      handler (newValue, oldValue) {
        if (this.readyForAPI) {
          axios.put(process.env.VUE_APP_BACKEND_URL + '/api/admin/board-chains/' + newValue[this.tempIndex].id, newValue[this.tempIndex])
            .catch(function (error) {
              console.log(error)
            })
        }
      }
    }
  },
  methods: {
    setTempVal (value) {
      this.tempIndex = value
    },
    getDataFromApi () {
      this.loading = true
      axios.post(process.env.VUE_APP_BACKEND_URL + '/api/admin/board-chains', { boardId: this.boardId, clientId: this.$store.state.client.activeClient.clientID })
        .then((response) => {
          this.approvalChains = response.data.chain_list
          this.loading = false
        }).catch(function (error) {
          console.log(error)
        }).then(() => {
          this.readyForAPI = true
        })
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
