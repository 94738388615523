<template>
  <v-navigation-drawer
    fixed
    temporary
    right
    width="600"
    v-model="show"
  >

    <div class="py-6 px-12">

      <v-row>
        <v-col>
          <h1 class="darkGrey--text font-weight-medium">
            {{ board.name }}
          </h1>
        </v-col>
      </v-row>

      <v-row class="mb-10">
        <v-col class="d-flex align-center">
            <span class="d-flex align-center" v-if="board.status === 'active'">
              <v-icon color="secondary" size="24">mdi-check-circle-outline</v-icon>
              <span class="secondary--text text-uppercase text-caption font-weight-medium ml-2">Active</span>
            </span>
          <span class="d-flex align-center" v-else>
              <v-icon color="important" size="24">mdi-close-circle-outline</v-icon>
              <span class="important--text text-uppercase text-caption font-weight-medium ml-2">Inactive</span>
            </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
              <span class="font-weight-light text-body-2">
                {{ board.description }}
              </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5" class="d-flex align-center">
          <v-icon color="secondary" size="34">
            mdi-clock-outline
          </v-icon>
          <span class="secondary--text text-uppercase text-caption ml-2">
            Usual<br> Meeting Time
          </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6">
          <span class="text-body-1 d-flex justify-end align-center text-right text-body-1">
            {{ board.meeting_time }}
          </span>
        </v-col>
      </v-row>

      <v-row class="mb-8">
        <v-col cols="5" class="d-flex align-center">
          <v-icon color="secondary" size="34">
            mdi-map-marker
          </v-icon>
          <span class="secondary--text text-uppercase text-caption ml-2">
            Usual<br> Meeting Location
          </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6">
          <span class="text-body-1 d-flex justify-end align-center text-right text-body-2">
            {{ board.meeting_location }}
          </span>
        </v-col>
      </v-row>

      <v-row class="mb-12 veryLightGrey px-6 py-4" v-if="board.members_count>0">
        <v-col class="custom-view-list">
          <span class="custom-view-title darkGrey--text">
            {{ board.members_count }} MEMBERS
          </span>
          <ul class="custom-list-very-big">
            <li v-for="member in board.members" :key="member.name" class="font-weight-light">
              {{ member.name }}
            </li>
          </ul>
        </v-col>
      </v-row>

      <v-row class="mb-16">
        <v-col>
              <span class="d-flex align-center">
                <v-icon color="secondary" size="30">mdi-share-variant</v-icon>
                <span class="text-uppercase darkGrey--text ml-4 font-weight-medium text-body-1">Social Media</span>
              </span>
        </v-col>
        <v-col class="d-flex">
          <v-img max-width="50" height="30" contain src="@/assets/social-facebook.svg"></v-img>
          <v-img max-width="50" height="30" contain src="@/assets/social-twitter.svg"></v-img>
          <v-img max-width="50" height="30" contain src="@/assets/social-reddit.svg"></v-img>
          <v-img max-width="50" height="30" contain src="@/assets/social-instagram.svg"></v-img>
          <v-img max-width="50" height="30" contain src="@/assets/social-nextdoor.svg"></v-img>
        </v-col>
      </v-row>

      <v-row v-if="board.approval_chains.approver==0">
        <v-col>
              <span class="d-flex align-center">
                <v-icon color="secondary" size="30">mdi-check-decagram</v-icon>
                <span class="text-uppercase darkGrey--text ml-4 font-weight-medium text-body-1">{{ board.approval_chains.length }} Approval Chains</span>
              </span>
        </v-col>
      </v-row>
      <v-row class="mb-16" v-if="board.approval_chains.approver==0">
        <v-col>
          <v-list class="custom-list-separator" disabled>
            <v-list-item-group>
              <v-list-item class="font-weight-light pa-0" v-for="approvalChain in board.approval_chains" :key="approvalChain.id">
                <v-list-item-content>
                  <v-list-item-title>{{ approvalChain.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
              <span class="d-flex align-center">
                <v-icon color="secondary" size="30">mdi-email</v-icon>
                <span class="text-uppercase darkGrey--text ml-4 font-weight-medium text-body-1">Auto-Notifications</span>
              </span>
        </v-col>
      </v-row>
      <v-row class="mb-16">
        <v-col>
          <v-expansion-panels flat accordion class="custom-panel-separator">
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-0">
                <span class="font-weight-light">
                  Board Members
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0 veryLightGrey">
                <span class="d-flex justify-space-between pt-4 darkGrey--text text-body-2">
                  <span class="darkGrey--text text-caption">Interval of 30 minutes</span>
                  <span class="d-flex">
                    <span class="d-flex align-center text-uppercase text-caption darkGrey--text px-2"><v-icon color="positive">mdi-check</v-icon>Agenda</span>
                    <span class="d-flex align-center text-uppercase text-caption lightGrey--text px-2"><v-icon color="lightGrey">mdi-cancel</v-icon>Packet</span>
                  </span>
                </span>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-0">
                <span class="font-weight-light">
                  Staff
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0 veryLightGrey">
                <span class="d-flex justify-space-between pt-4 darkGrey--text text-body-2">
                  <span class="darkGrey--text text-caption">Interval of 30 minutes</span>
                  <span class="d-flex">
                    <span class="d-flex align-center text-uppercase text-caption darkGrey--text px-2"><v-icon color="positive">mdi-check</v-icon>Agenda</span>
                    <span class="d-flex align-center text-uppercase text-caption lightGrey--text px-2"><v-icon color="lightGrey">mdi-cancel</v-icon>Packet</span>
                  </span>
                </span>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-0">
                <span class="font-weight-light">
                  Public
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0 veryLightGrey">
                <span class="d-flex justify-space-between pt-4 darkGrey--text text-body-2">
                  <span class="darkGrey--text text-caption">Interval of 30 minutes</span>
                  <span class="d-flex">
                    <span class="d-flex align-center text-uppercase text-caption darkGrey--text px-2"><v-icon color="positive">mdi-check</v-icon>Agenda</span>
                    <span class="d-flex align-center text-uppercase text-caption lightGrey--text px-2"><v-icon color="lightGrey">mdi-cancel</v-icon>Packet</span>
                  </span>
                </span>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-0">
                <span class="font-weight-light">
                  Media
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0 veryLightGrey">
                <span class="d-flex justify-space-between pt-4 darkGrey--text text-body-2">
                  <span class="darkGrey--text text-caption">Interval of 30 minutes</span>
                  <span class="d-flex">
                    <span class="d-flex align-center text-uppercase text-caption darkGrey--text px-2"><v-icon color="positive">mdi-check</v-icon>Agenda</span>
                    <span class="d-flex align-center text-uppercase text-caption lightGrey--text px-2"><v-icon color="lightGrey">mdi-cancel</v-icon>Packet</span>
                  </span>
                </span>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-btn depressed @click="show=false">
        Close
      </v-btn>

    </div>

  </v-navigation-drawer>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'BoardView',
  props: {
    value: {
      required: true,
      type: Boolean
    },
    board: {
      required: true
    }
  },
  methods: {
    close () {
      this.$emit('closed')
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
