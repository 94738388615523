<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-icon color="secondary" size="36" class="mr-3">mdi-gavel</v-icon>
          <h1 class="darkGrey--text">Boards</h1>
<!--          <v-btn-->
<!--            depressed-->
<!--            height="40"-->
<!--            color="orange lighten-2"-->
<!--            class="white&#45;&#45;text"-->
<!--            @click="addNotification()"-->
<!--          >Add Notification</v-btn>-->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Boards
            </span>
          </v-tooltip>
        </span>

        <v-spacer></v-spacer>

        <v-btn
          depressed
          height="40"
          color="orange lighten-2"
          class="white--text"
          @click="openModal('createUpdate', 'create')"
        >
          <v-icon size="18">mdi-plus</v-icon> Add Board
        </v-btn>

      </v-col>
    </v-row>

    <v-row class="my-6">
      <v-col>
        <v-divider class="custom-divider"></v-divider>
      </v-col>
    </v-row>

    <v-row class="d-flex mb-6">
      <v-col>
        <v-checkbox
          color="secondary"
          label="Show Deactivated Boards"
          v-model="deactivatedBoards"
          hide-details
          class="custom-checkbox-small"
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          @change="getDataFromApi"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="boards"
            class="elevation-1"
            :footer-props="{
              'items-per-page-options': [10, 20, 30]
            }"
            :server-items-length="totalBoards"
            :options.sync="options"
            :loading="loading"
            :search="search"
          >
            <template v-slot:item.name="{ item }">
              <span class="py-6 d-flex flex-column">
                <span class="font-weight-bold">
                  {{ item.name }}
                </span>
                <span class="text-caption">
                  {{ item.description }}
                </span>
              </span>
            </template>
            <template v-slot:item.status="{ item }">
              <span class="text-caption">
                {{ item.status }}
              </span>
            </template>
            <template v-slot:item.members_count="{ item }">
              <v-tooltip top v-if="item.members_count>0">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.members_count }}
                  </v-chip>
                </template>
                <span class="custom-tooltip-list">
                  <v-row>
                    <v-col>
                      <span class="custom-tooltip-title">
                        MEMBERS
                      </span>
                      <ul v-bind:class="{ 'custom-list-big': item.members.length>16 }">
                        <li v-for="member in item.members" :key="member.name">
                          {{ member.first_name }} {{ member.last_name }}
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </span>
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex flex-row justify-center">

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="darkGrey"
                      size="18"
                      v-bind="attrs"
                      v-on="on"
                      @click="viewBoard(item)"
                    >
                      <v-icon size="20">
                        mdi-eye-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    View details
                  </span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="darkGrey"
                      size="18"
                      v-bind="attrs"
                      v-on="on"
                      @click="editBoardMembers(item.id, item.name)"
                    >
                      <v-icon size="20">
                        mdi-account-multiple
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Manage Members
                  </span>
                </v-tooltip>

                <v-menu transition="slide-x-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon size="20">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                        v-for="(itemMenu, index) in itemsMenuFiltered(item)"
                        :key="index"
                        @click="openModal(itemMenu.actions, itemMenu.optional, item.name, item.meeting_time, item.description, item.meeting_location, item.id)"
                        dense
                      >
                        <v-list-item-icon>
                          <v-icon
                            :color="itemMenu.type? itemMenu.type:'darkGrey'"
                          >
                            {{ itemMenu.icon }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            :class="itemMenu.type? itemMenu.type+'--text':'darkGrey--text'"
                          >
                            {{ itemMenu.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>

              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <board-view v-model="modals.view" v-if="modals.view" :board="selectedBoard" />
    <board-create-update @reload="getDataFromApi" v-model="modals.createUpdate" :modeType="modeType" :name="board.name" :meetingTime="board.meetingTime" :description="board.description" :meetingLoc="board.meetingLoc" :boardId="board.boardId"></board-create-update>
    <board-auto-notification v-model="modals.autoNotification" :boardId="board.boardId" :name="board.name"></board-auto-notification>
    <board-social-media v-model="modals.socialMedia" :boardId="board.boardId" :name="board.name"></board-social-media>
    <board-approval-chain v-model="modals.approvalChain" :boardId="board.boardId" :name="board.name"></board-approval-chain>
    <board-options v-model="modals.options" :boardId="board.boardId" :name="board.name"></board-options>
    <board-widget-design v-model="modals.widgetDesign" :boardId="board.boardId" :name="board.name"></board-widget-design>
    <board-activate :boardId="board.boardId" :name="board.name" v-model="modals.activate"></board-activate>
    <board-deactivate :boardId="board.boardId" :name="board.name" v-model="modals.deactivate"></board-deactivate>
    <board-delete :boardId="board.boardId" :name="board.name" v-model="modals.delete"></board-delete>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import BoardCreateUpdate from '@/components/Boards/BoardCreateUpdate'
import BoardAutoNotification from '@/components/Boards/BoardAutoNotification'
import BoardSocialMedia from '@/components/Boards/BoardSocialMedia'
import BoardApprovalChain from '@/components/Boards/BoardApprovalChain'
import BoardOptions from '@/components/Boards/BoardOptions'
import BoardWidgetDesign from '@/components/Boards/BoardWidgetDesign'
import BoardDeactivate from '@/components/Boards/BoardDeactivate'
import BoardActivate from '@/components/Boards/BoardActivate'
import BoardDelete from '@/components/Boards/BoardDelete'
import Board from '@/models/admin/Board'
import BoardView from '@/components/Boards/BoardView'

export default Vue.extend({
  name: 'Boards',
  components: {
    Portal,
    BoardCreateUpdate,
    BoardAutoNotification,
    BoardSocialMedia,
    BoardApprovalChain,
    BoardOptions,
    BoardWidgetDesign,
    BoardDeactivate,
    BoardActivate,
    BoardDelete,
    BoardView
  },
  data: () => ({
    selectedBoard: null,
    modeType: '',
    board: {
      name: '',
      description: '',
      meetingTime: '',
      meetingLoc: '',
      boardId: 0
    },
    modals: {
      createUpdate: false,
      approvalChain: false,
      options: false,
      socialMedia: false,
      widgetDesign: false,
      activate: false,
      deactivate: false,
      delete: false,
      autoNotification: false,
      view: false
    },
    breadcrumbsItems: [
      {
        text: 'Admin Panel',
        disabled: false,
        href: ''
      },
      {
        text: 'Boards',
        disabled: true,
        href: ''
      }
    ],
    itemsMenu: [
      { icon: 'mdi-pencil', text: 'Edit Name / Time / Location', actions: 'createUpdate', optional: 'edit' },
      { icon: 'mdi-email', text: 'Auto-Notification', actions: 'autoNotification' },
      { icon: 'mdi-share-variant', text: 'Social Media', actions: 'socialMedia' },
      { icon: 'mdi-link', text: 'Approval Chain Linkages', actions: 'approvalChain' },
      { icon: 'mdi-cog', text: 'Board Options', actions: 'options' },
      { icon: 'mdi-view-compact', text: 'Meeting List Widget Design', actions: 'widgetDesign' },
      { icon: 'mdi-check-circle-outline', text: 'Activate', actions: 'activate' },
      { icon: 'mdi-close-circle-outline', text: 'Deactivate', type: 'important', actions: 'deactivate' },
      { icon: 'mdi-delete', text: 'Delete', type: 'important', actions: 'delete' }
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Members', value: 'members_count', sortable: false, align: 'center' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'center' }
    ],
    boards: [],
    totalBoards: 0,
    loading: false,
    deactivatedBoards: false,
    options: {},
    search: ''
  }),
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    },
    deactivatedBoards () {
      this.getDataFromApi()
    }
  },
  created () {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'client/setActiveClient') {
        if (state.activeClient !== null) {
          this.getDataFromApi()
        }
      }
    })
  },
  beforeDestroy () {
    this.unsubscribe()
  },
  mounted () {
    this.options = {
      page: 1,
      itemsPerPage: 10
    }
    this.getDataFromApi()
  },
  computed: {
    boardStatus () {
      return (this.deactivatedBoards) ? '' : 'active'
    }
  },
  methods: {
    addNotification () {
      console.log('adding to dispatch addNotification')
      this.$store.dispatch('addNotification', {
        type: 'success',
        message: 'Daniel was here'
      })
    },
    itemsMenuFiltered (item) {
      const itemsToRemove = []

      if (item.status === 'active') {
        itemsToRemove.push('activate')
      }

      if (item.status === 'inactive') {
        itemsToRemove.push('deactivate')
      }

      return this.itemsMenu.filter(function (el) { return !itemsToRemove.includes(el.actions) })
    },
    getRawText (text) {
      return text.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '')
    },
    editBoardMembers (itemId, itemName) {
      this.$store.commit('changeBoardName', itemName)
      this.$router.push({ name: 'BoardMembers', params: { id: itemId } })
    },
    getDataFromApi () {
      this.loading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      let orderBy = null
      if (sortBy !== undefined && sortBy[0] !== undefined) {
        const sortDir = sortDesc[0] ? '-' : ''
        orderBy = sortDir + sortBy[0]
      }

      Board
        .page(page)
        .where('name', this.search)
        .where('description', this.search)
        .where('status', this.boardStatus)
        .where('client_id', this.$store.state.client.activeClient.clientID)
        .orderBy(orderBy)
        .get()
        .then(response => {
          this.boards = response.data
          this.totalBoards = response.total
          this.loading = false
        })
    },
    viewBoard (board) {
      this.selectedBoard = board
      this.modals.view = true
    },
    openModal (item, optional, name, meetingTime, description, meetingLoc, boardId) {
      if (optional) this.modeType = optional
      this.modals[item] = true
      if (name) this.board.name = name
      if (meetingTime) this.board.meetingTime = meetingTime
      if (description) this.board.description = description
      if (meetingLoc) this.board.meetingLoc = meetingLoc
      if (boardId) this.board.boardId = boardId
    }
  }
})
</script>
