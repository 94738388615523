<template>

  <v-dialog
    width="900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Board Options of <span class="font-weight-black">{{ name }}</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-skeleton-loader
        v-if="loading"
        class="mx-auto"
        max-width="100%"
        type="image, actions"
      ></v-skeleton-loader>

      <v-card-text v-if="!loading">

        <template>

          <v-row class="py-2" v-for="(option, index) in options" v-bind:key="option.id" v-bind:option="option">
            <v-col>
              <v-switch
                inset
                :label="option.name"
                color="secondary"
                class="mt-0 pa-0"
                v-model="option.value"
                @click="setTempVal(index)"
                hide-details
              ></v-switch>
            </v-col>
            <v-col class="py-0 d-flex align-center text-caption">
              {{ option.description }}
            </v-col>
          </v-row>

          <v-row>
            <v-divider class="custom-divider"></v-divider>
          </v-row>

        </template>

        <v-row class="py-6 px-3 mt-6">
          <v-col class="secondary--text text-uppercase d-flex align-center font-weight-bold text-body-1">
            Options not included in your plan
          </v-col>
          <v-col class="d-flex align-center">
            <v-btn depressed color="secondary">
              Upgrade
            </v-btn>
          </v-col>
        </v-row>

        <template>

          <v-row class="py-2">
            <v-col>
              <v-switch
                inset
                label="Create Custom Chain"
                color="secondary"
                hide-details
                disabled
                class="mt-0 pa-0"
              ></v-switch>
            </v-col>
            <v-col class="py-0 d-flex align-center opacity-default text-caption">
              Public Works Department Head, City Secretary, City Manager’s Office
            </v-col>
          </v-row>

          <v-row>
            <v-divider class="custom-divider"></v-divider>
          </v-row>

          <v-row class="py-2">
            <v-col>
              <v-switch
                inset
                label="Another Example of Option"
                color="secondary"
                hide-details
                disabled
                class="mt-0 pa-0"
              ></v-switch>
            </v-col>
            <v-col class="py-0 d-flex align-center opacity-default text-caption">
              Public Works Department Head
            </v-col>
          </v-row>

          <v-row>
            <v-divider class="custom-divider"></v-divider>
          </v-row>

          <v-row class="py-2">
            <v-col>
              <v-switch
                inset
                label="And One More Option Not Included"
                color="secondary"
                hide-details
                disabled
                class="mt-0 pa-0"
              ></v-switch>
            </v-col>
            <v-col class="py-0 d-flex align-center opacity-default text-caption">
              City Secretary, City Manager’s Office
            </v-col>
          </v-row>

        </template>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

// Initial state
function initialState () {
  return {
    options: '',
    loading: true,
    tempIndex: null,
    readyForAPI: false
  }
}

export default Vue.extend({
  name: 'BoardOptions',
  props: {
    value: {
      required: true,
      type: Boolean
    },
    boardId: {
      required: false,
      type: Number
    },
    name: {
      required: true,
      type: String
    }
  },
  data: function () {
    return initialState()
  },
  methods: {
    setTempVal (value) {
      this.tempIndex = value
    },
    getDataFromApi () {
      this.loading = true
      axios.post(process.env.VUE_APP_BACKEND_URL + '/api/admin/board-options', { boardId: this.boardId, clientId: this.$store.state.client.activeClient.clientID })
        .then((response) => {
          this.options = response.data
          this.loading = false
        }).catch(function (error) {
          console.log(error)
        }).then(() => {
          this.readyForAPI = true
        })
    }
  },
  watch: {
    show (value) {
      if (value === true) {
        this.getDataFromApi()
      }
    },
    options: {
      deep: true,
      handler (newValue, oldValue) {
        if (this.readyForAPI && newValue[this.tempIndex]) {
          axios.put(process.env.VUE_APP_BACKEND_URL + '/api/admin/board-options/' + newValue[this.tempIndex].id, { boardId: this.boardId, data: newValue[this.tempIndex], clientId: this.$store.state.client.activeClient.clientID })
            .then((response) => {
              console.log(response)
            }).catch(function (error) {
              console.log(error)
            })
        }
      }
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
